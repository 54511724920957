import React, { Component } from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import packageJson from "../package.json";
import favicon from "./assets/favicon.png";

declare global {
  interface Window {
    opera: any;
  }
}

export default class App extends Component {
  redirectTimeout: any = null;

  componentDidMount() {
    //INFO : Google Analytics
    if (
      process.env.NODE_ENV !== "development" &&
      process.env.REACT_APP_GOOGLE_ANALYTICS
    ) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    this.redirectTimeout = setTimeout(() => {
      //INFO : Navigator
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {
        window.alert(
          "Me Books App is not available on this device. Please use an Android or IOS device"
        );
      } else if (/android/i.test(userAgent)) {
        window.location.href =
          "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=co.mebooks.plus";
      } else if (
        /iPad|iPhone|iPod|AppleWebKit/.test(userAgent) &&
        !window.MSStream
      ) {
        window.location.href = "https://apps.apple.com/us/app/id1510382298";
      } else {
        window.location.href =
          "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=co.mebooks.plus";
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }
  }

  render() {
    return (
      <Container>
        <img
          alt="Me Books Download"
          src={favicon}
          style={{ width: 120, height: 120 }}
        />
        <Title>Redirecting to Download Portal</Title>
        <Description>Please Hold On...</Description>
        <Footer>Version {packageJson.version}</Footer>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-family: dm-sans;
  font-weight: bold;
  font-size: 18px;
  color: #2b2b2b;
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-family: dm-sans;
  font-weight: 500;
  font-size: 14px;
  color: #666;
`;

const Footer = styled.p`
  font-family: dm-sans;
  font-weight: 500;
  font-size: 12px;
  color: #666;
  position: absolute;
  bottom: 10px;
  right: 20px;
`;
